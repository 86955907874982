<template>
  <dimmer :active="isLoading">
    <h1 class="text-center">{{message}}: {{$route.params.id}}</h1>
    <div class="text-center">
      <img v-if="imageUrl" :src="imageUrl"/>
    </div>
  </dimmer>
</template>

<script>

import {images} from '@/services';
import download from '@/mixins/download';

export default {
  name: 'DownloadPurchaseOrderInvoice',
  mixins: [download],
  data() {
    return {
      isLoading: false,
      imageUrl: null,
      message: 'Downloading invoice',
    };
  },
  async mounted() {
    this.isLoading = true;

    try {
      const invoice = await images.downloadInvoice(this.$route.params.id);
      const urlCreator = window.URL || window.webkitURL;
      this.imageUrl = urlCreator.createObjectURL(invoice.data);
    }
    catch (e) {
      this.message = 'Failed to download';
    }
    finally {
      this.isLoading = false;
    }
  },
};
</script>
